body {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-image: url(/media/background.jpg);
    background-size: cover;
    color: #3b528e;
}

div#root {
    width: 100%
}

div#content, div#footer {
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
}

div#content {
    margin-top: -1em;
}

div#footer {
    background-color: #cde3e8;
    margin-top: -1em;
    padding: 8em 0 2em 0;
}

div#footer span {
    font-size: 10px;
}

a {
    color: #436ba5;
}

ul {
    padding-inline-start: 0;
}

.circle-color1 {
    background-color: rgba(87,95,143,0.5)
}

.circle-color2 {
    background-color: rgba(153,177,35,0.5)
}

.circle-color3 {
    background-color: rgba(143,99,173,0.5)
}

.circle-color4 {
    background-color: rgba(201,215,189,0.5)
}

.circle-color5 {
    background-color: rgba(211,139,175,0.5)
}

.circle-color6 {
    background-color: rgba(43,165,195,0.5)
}

.circle-color7 {
    background-color: rgba(253,219,41,0.5)
}
