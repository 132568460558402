div#home {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div#home div.inner-content {
    width: 100%;
    text-align: center;
}

div#home div#nav-blocks div {
    width: 150px;
    height: 150px;
    vertical-align: middle;
    text-align: center;
}

@keyframes fade {
    0% { opacity:0; }
    6% { opacity:1; }
    24% { opacity:1; }
    30% { opacity:0; }
    100% { opacity:0; }
}

div#home div#banner-a {
    height: 0;
    width: 100%;
    padding-top: 42.16%;
    position: relative;
}

div#home div#banner-a div.slide {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 42.16%;
    left: 0;
    top: 0;
    opacity:0;
}

/* 30s = 5 slides * 6 seconds for each transition */
div#home div#banner-a div.slide:nth-child(1) {
    background-image: url(/media/home/slide1.jpg);
    animation: fade 30s infinite 0s linear;
}

div#home div#banner-a div.slide:nth-child(2) {
    background-image: url(/media/home/slide2.jpg);
    animation: fade 30s infinite 6s linear;
}

div#home div#banner-a div.slide:nth-child(3) {
    background-image: url(/media/home/slide3.jpg);
    animation: fade 30s infinite 12s linear;
}

div#home div#banner-a div.slide:nth-child(4) {
    background-image: url(/media/home/slide4.jpg);
    animation: fade 30s infinite 18s linear;
}

div#home div#banner-a div.slide:nth-child(5) {
    background-image: url(/media/home/slide5.jpg);
    animation: fade 30s infinite 24s linear;
}

div#home div#banner-b,
div#home div#factory-cert-disclaimer,
div#home div#category-list,
div#home div#factory-images,
div#home div.carousel {
    margin-top: 1.5em
}

div#home div#factory-images {
    display: flex;
    height: 436px;
    width: 100%;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/media/front-page-factory.jpg);
    justify-content: center;
    align-items: center
}

div#home div#factory-cert-disclaimer {
    width: 100%;
    background-color: transparent;
    padding: 1.5em 0 0 0;
    min-height: 150px;
}

div#home div#factory-cert-disclaimer div.home-cert-blocks {
    width: 225px;
    height: 225px;
    background-color: white;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px
}

div#home div#factory-cert-disclaimer div.home-cert-blocks img {
    width: 100%;
    height: 100%;
}

div#home div#banner-b {
    background-color: white;
    background-image: url(/media/home/legacy.jpg);
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    min-height: 113px;
    text-align: center;
    width: 100%;
    padding: 15px;
}

div#home div#banner-b span {visibility: hidden;}

div#home div#category-list {
    background-color: #cde3e8;
    padding-top: 1.5em
}

div#home div#category-list div.slick-slider button.slick-arrow {
    position: absolute;
    height: 100%;
    color: white;
    background-color: #cde3e8;
    width: 30px;
    z-index: 999;
}

div#home div#category-list div.slick-slider button.slick-arrow.slick-prev::before,
div#home div#category-list div.slick-slider button.slick-arrow.slick-next::before {
    font-family: inherit;
    line-height: inherit;
    transform: scale(1.25,8);
    display: inline-block;
}


div#home div#category-list div.slick-slider button.slick-arrow.slick-prev {
    left: 0;
}

div#home div#category-list div.slick-slider button.slick-arrow.slick-prev::before {
    content: "<";
}

div#home div#category-list div.slick-slider button.slick-arrow.slick-next {
    right: 0;
}

div#home div#category-list div.slick-slider button.slick-arrow.slick-next::before {
    content: ">";
}

div#home div#category-list div.slick-slide a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

div#home div#category-list div.slick-slide a div.categoryImage {
    width: 200px;
    height: 200px;
    background-color: white;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
}

div#home div#category-list div.slick-slide a:hover {
    text-decoration: underline;
}

div#category-list div.slick-slider div.slick-slide div.legend {
    word-wrap: break-word;
    width: 10em;
}

@media only screen and (max-width: 640px) {
    div#home div#banner-a {
        padding-top: 100%;
    }

    div#home div#banner-a div.slide {
        padding-bottom: 100%
    }

    div#home div.inner-content {
        width: 100%;
    }
    div#home div#banner-a div.slide:nth-child(1) {
        background-image: url(/media/home/slide1-small.jpg);
        background-position-x: -31vw;
    }
    div#home div#banner-a div.slide:nth-child(2) {
        background-image: url(/media/home/slide2-small.jpg);
    }
    div#home div#banner-a div.slide:nth-child(3) {
        background-image: url(/media/home/slide3-small.jpg);
    }
    div#home div#banner-a div.slide:nth-child(4) {
        background-image: url(/media/home/slide4-small.jpg);
    }
    div#home div#banner-a div.slide:nth-child(5) {
        background-image: url(/media/home/slide5-small.jpg);
    }
    div#home div#factory-cert-disclaimer div.home-cert-blocks {
        width: 100vw;
        height: 100vw;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1em;
    }
    div#home div#category-list div.slick-slide a div.categoryImage {
        width: 90vw;
        height: 90vw;
    }
    div#category-list div.slick-slider div.slick-slide div.legend {
        width: auto;
        max-width: 80vw;
        margin-bottom: 1.5em;
    }
}

@media only screen and (min-width: 641px) {
    div#home div#category-list div.carousel ul.slider li.slide {
        min-width: 33% !important
    }

    div#home div.inner-content div#factory-cert-disclaimer {
        min-width: 900px
    }
}

@media only screen and (min-width: 1280px) {
    div#home div.inner-content {
        width: 1024px;
    }
    div#home div#banner-b {
        min-width: 900px;
    }
}
