div#about {
    background-color: white;
    color: #436ba5;
    overflow: auto;
    max-width: 1024px
}

div#about div.banner {
    display: flex;
    height: 300px;
    background-color: white;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/media/our-story.jpg);
    justify-content: center;
    align-items: center;
    padding-top: 14.5%;
    padding-bottom: 14.5%;
}

div#about h2 {
    margin-top: 1.5em;
}

div#about div.content {
    padding: 2em
}

div#about div.content div.inner-content {
    padding: 0 1em;
}

div#about div.content div.inner-content p {
    text-align: justify;
}

div#about div#our-clients {
    width: 100%
}

div#about div#our-clients div {
    width: 33%;
    max-width: 480px;
    float: left;
    margin-top: 5px
}

div#about div#our-clients div img {
    max-width: 100%;
    height: auto
}

@media only screen and (max-width: 480px) {
    div#about div#our-clients div {
        width: 100%
    }
    div#about div.banner {
        background-position-x: -80vw;
    }
}
