div#contact {
    background-color: white;
    color: #4a63b5;
    padding: 2em;
    overflow: auto;
    width: 1024px
}

div#contact img.logo {
    width: 100%;
    height: 100%;
}

div#contact div.contact-info {
    display: table;
    width: 100%
}

div#contact div.contact-info div.line {
    display: table-row;
}

div#contact div.contact-info div.line span.company-name {
    text-align: center;
}

div#contact div.contact-info div.line strong, div#contact div.contact-info div.line strong span {
    display: table-cell;
}

div#contact div.contact-info div.line strong {
    text-align: right;
    font-weight: bold;
    width: 15em
}

div#contact div.contact-info div.line span {
    text-align: left;
    padding: 5px 0 5px 5px;
}

div#contact iframe {
    width: 100%;
    height: 350px;
    margin-top: 1.5em
}
