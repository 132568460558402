div#certifications-and-reports {
    background-color: white;
    color: #436ba5;
    overflow: auto;
    width: 1024px
}

div#certifications-and-reports div.banner {
    display: flex;
    background-color: white;
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url(/media/certs-and-reports.jpg);
    background-size: cover;
    justify-content: center;
    align-items: center;
    padding-top: 14.5%;
    padding-bottom: 14.5%;
}

div#certifications-and-reports div.banner span {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: white;
}

div#certification-and-reports div.content {
    padding: 2em;
}

div#certifications-and-reports div#certification-blocks {
    margin-top: 1.5em
}

div#certification-blocks div.slick-slider div.slick-slide div.cert-preview {
    width: 100%;
    padding-bottom: 100%
}

div#certification-blocks div.cert-preview div.background {
    width: 100%;
}

div#certification-blocks div.cert-preview {
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
}

div#certification-blocks div.cert-preview div.background {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
}

div#certification-blocks div.cert-preview div.caption {
    font-size: 16px;
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100%
}

div#certification-blocks div.cert-preview a:hover div.caption {
    text-decoration: underline;
}

div#certification-blocks div.slick-slider div.slick-slide div.cert-preview div.background,
div#certification-blocks div.cert-preview div.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
}

div#certification-blocks div.cert-preview.odd {
    background-color: #cee3e8
}

div#certification-blocks div.cert-preview.even {
    background-color: #a9cad3
}

div#certifications-and-reports div.cert-preview a div.background {
    width: 200px;
    height: 200px;
    border-color: white;
    border-radius: 70%;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

div#certifications-and-reports div#cert-block1 a div.background {
    background-image: url(/media/certificates-and-reports/01_BSCI_Audit_Report.jpg);
}

div#certifications-and-reports div#cert-block2 a div.background {
    background-image: url(/media/certificates-and-reports/02_Sedax_CAPR.jpg);
}

div#certifications-and-reports div#cert-block3 a div.background {
    background-image: url(/media/certificates-and-reports/03_Disney_FAMA.jpg);
}

div#certifications-and-reports div#cert-block4 a div.background {
    background-image: url(/media/certificates-and-reports/04_Disney_Shanghai_FAMA.jpg);
}

div#certifications-and-reports div#cert-block5 a div.background {
    background-image: url(/media/certificates-and-reports/05_Universal_Studios_Factory_Audit_Report.jpg);
}

div#certifications-and-reports div#cert-block6 a div.background {
    background-image: url(/media/certificates-and-reports/06_walmart_factory_audit_2017.jpg);
}

div#certifications-and-reports div#cert-block7 a div.background {
    background-image: url(/media/certificates-and-reports/07_Factory_Audit_Certification_by_CVS_Health.jpg);
}

div#certifications-and-reports div#cert-block8 a div.background {
    background-image: url(/media/certificates-and-reports/08_QS_Certificate_small.jpg);
}

div#certifications-and-reports div#cert-block9 a div.background {
    background-image: url(/media/certificates-and-reports/09_Testing_Report_by_FDA.jpg);
}

div#certifications-and-reports div#cert-block10 a div.background {
    background-image: url(/media/certificates-and-reports/10_Testing_Report_by_China_Regulation.jpg);
}

div#certifications-and-reports div#cert-block11 a div.background {
    background-image: url(/media/certificates-and-reports/11_Testing_Report_by_EU.jpg);
}

div#certifications-and-reports div#cert-block12 a div.background {
    background-image: url(/media/certificates-and-reports/12_VOM_testing_report_by_Intertek_Thermos.jpg);
}

@media only screen and (min-width: 640px) {
    div#certification-blocks div.cert-preview {
        padding-bottom: 100%
    }
    div#certification-blocks div.cert-preview div.background {
        width: 100%;
    }
}

@media only screen and (max-width: 799px) {

    div#certification-blocks div.slick-slider div.slick-slide div.cert-preview div.background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1
    }
}

@media only screen and (min-width: 800px) {
    div#certification-blocks div.fluid-grid div.cert-preview {
        width: 31%;
        padding-bottom: 31%;
        float: left;
        height: auto;
        margin: 10px;
    }
}

@media only screen and (max-width:480px) {
    div#certifications-and-reports div.banner {
        padding-top: 33.3%;
        padding-bottom: 33.3%;
    }
}
