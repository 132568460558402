header#head-banner {
    width: 100%;
    /* height: 3em; */
    background-color: white;
    position: sticky;
    display: block;
    padding-bottom: 1em;
}

@media only screen and (max-width:480px) {
    div.logo {
        vertical-align: middle;
        display: inline;
    }
    div.logo img {
        width: 60vw;
    }
}

@media only screen and (min-width:480px) {
    div.logo img {
        width: 20%;
        height:auto;
    }
}
