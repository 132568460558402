nav#navbar {
    vertical-align: middle;
    text-align: center;
    width: 100%
}

nav#navbar ul {
    list-style-type: none;
    width: 100%
}

nav#navbar ul li {
    display: inline-block;
    padding: 0px 2em;
    box-sizing: border-box;
}

nav#navbar label, #hamburger {
    display: none
}

@media only screen and (max-width: 640px) {
    header#head-banner label {
        font-size: 2em;
        float: right;
        margin-right: 1em;
        margin-left: -1.3em;
        margin-top: 0.45em;
    }
    nav#navbar ul li {
        display: block;
    }
    nav#navbar ul {
        display: none;
    }
    nav#navbar input:checked ~ ul {
        display: block;
    }
}

@media only screen and (min-width: 641px) {
    header#head-banner div.logo label {
        display:none
    }
}
