div.product-list-item {
    display: block;
    float: left;
    position: relative;
    width: 325px;
    height: 325px;
    margin-top: 15px;
    margin-right: 7px;
    margin-left: 7px;
    background-color: white
}

div.product-list-item div.product-image,
div.product-list-item div.text-description,
div.product-list-item div.overlay-circle {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
}

div.product-list-item div.product-image {
    z-index: 0;
    width: 250px;
    height: 250px;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

div.product-list-item div.overlay-circle {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    z-index: 1
}

div.product-list-item div.text-description {
    z-index: 2
}

div.product-list-item div.text-description a {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: white !important;
}

@media screen and (max-width: 480px) {
    div.product-list-item {
        width: 100%;
        margin-bottom: 15px
    }
    div.product-list-item div.product-image {
        max-width: 300px;
        max-height: 300px;
        width: 100%;
        height: 100%
    }
    div.product-list-item div.overlay-circle {
        width: 250px;
        height: 250px
    }
    div.product-list-item div.text-description a {
        font-size: 18px
    }
}
