div#nav-footer {
    margin-top: 1.5em;
    padding-top: 1.5em;
    width: 100%;
    background-color: #cde3e8;
}

div#nav-footer ul {
    list-style-type: none;
    width: 100%;
}
div#nav-footer ul li {
    display: inline-block;
    margin: 0 20px;
    padding: 0 20px;
    width: 20%;
    border-bottom: 2px solid #8f92b0;
}

@media only screen and (max-width: 799px) {
    div#nav-footer ul li a {
        font-size: 48px;
    }
}

@media only screen and (min-width: 800px) {
    div#nav-footer ul li a span { display: none }
    div#nav-footer ul li#footer-link-home a::after { content: "Home" }
    div#nav-footer ul li#footer-link-2 a::after { content: "Terms & Policy" }
    div#nav-footer ul li#footer-link-3 a::after { content: "Contact Us" }
}
