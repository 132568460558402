div#product-list,
div#category-list {
    color: #436ba5;
    overflow: auto;
    width: 1024px;
}

div#category-list {background-color: transparent}
div#product-list {background-color: transparent}

div#product-list div.banner,
div#category-list div.banner {
    clear: both;
    display: flex;
    background-color: white;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/media/drinkware.jpg);
    justify-content: center;
    align-items: center;
    padding-top: 14.5%;
    padding-bottom: 14.5%;
}

div#product-list div.banner span,
div#category-list div.banner span {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: white;
}

div#product-list div.products,
div#category-list div.categories {
    margin-top: 1.5em;
    text-align: left;
    padding: 15px;
}

div#products,
div#categories {
    margin-top: 1.5em
}

div#product-list div.logo-production-methods-banner {
    clear: both;
    display: flex;
    height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url(https://via.placeholder.com/900x100.jpg);
}

@media screen and (max-width: 480px) {
    div#product-list,
    div#category-list {
        width: 100%;
    }
    div#category-list div.banner,
    div#product-list div.banner {
        padding-top: 33.3%;
        padding-bottom: 33.3%;
    }
}
