div#navigation-blocks {
    width: 100%;
    margin-top: 1.5em
}

div#navigation-blocks div.block {
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0.65%;
    width: 100vw;
    height: 100vw;
}

div#navigation-blocks div.block div.caption,
div#navigation-blocks div.block div.background {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
}

div#navigation-blocks div.block div.caption {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 700;
    font-size: 28px;
    z-index: 2;
}

div#navigation-blocks div.slick-slider div.slick-slide div.block div.background,
div#navigation-blocks div.block div.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
}

div#navigation-blocks div#nav-block1 div.background {
    background-image: url(/media/front-navi1.jpg);
}

div#navigation-blocks div#nav-block2 div.background {
    background-image: url(/media/front-navi2.jpg);
}

div#navigation-blocks div#nav-block3 div.background {
    background-image: url(/media/front-navi3.jpg);
}

div#navigation-blocks div#nav-block4 div.background {
    background-image: url(/media/front-navi4.jpg);
}

div#navigation-blocks div#nav-block5 div.background {
    background-image: url(/media/front-navi5.jpg);
}

div#navigation-blocks div#nav-block6 div.background {
    background-image: url(/media/front-navi6.jpg);
}

div#navigation-blocks div#nav-block4 > div.caption a,
div#navigation-blocks div#nav-block6 > div.caption a {
    color: white;
}

div#navigation-blocks div.slick-slider div.slick-slide div.block {
    width: 60%;
    padding-bottom: 60%
}

div#navigation-blocks div.block div.background {
    width: 100%;
    padding-bottom: 100%;
}

div#navigation-blocks div.slick-slider button.slick-arrow {
    position: absolute;
    height: 100%;
    color: white;
    background-color: #cde3e8;
    width: 30px;
    z-index: 999;
}

div#navigation-blocks div.slick-slider button.slick-arrow.slick-prev::before,
div#navigation-blocks div.slick-slider button.slick-arrow.slick-next::before {
    font-family: inherit;
    line-height: inherit;
    transform: scale(1.25,8);
    display: inline-block;
}


div#navigation-blocks div.slick-slider button.slick-arrow.slick-prev {
    left: 0;
}

div#navigation-blocks div.slick-slider button.slick-arrow.slick-prev::before {
    content: "<";
}

div#navigation-blocks div.slick-slider button.slick-arrow.slick-next {
    right: 0;
}

div#navigation-blocks div.slick-slider button.slick-arrow.slick-next::before {
    content: ">";
}

@media only screen and (min-width: 640px) {
    div#navigation-blocks div.block div.background {
        width: 100%;
    }
}

@media only screen and (max-width: 799px) {

    div#navigation-blocks div.slick-slider div.slick-slide div.block div.background {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 1
    }
}

@media only screen and (min-width: 800px) {
    div#navigation-blocks div.fluid-grid div.block {
        width: 32%;
        padding-bottom: 32%;
        float: left;
        height: auto;
    }
}
