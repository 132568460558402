div#product-view {
    background-color: white;
    color: #436ba5;
    overflow: auto;
    width: 1024px;
}

div#product-view div.banner {
    clear: both;
    display: flex;
    height: 300px;
    background: rgb(144,190,203); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(144,190,203,1) 0%, rgba(144,190,203,1) 20%, rgba(97,141,152,1) 50%, rgba(144,190,203,1) 80%, rgba(144,190,203,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(144,190,203,1) 0%,rgba(144,190,203,1) 20%,rgba(97,141,152,1) 50%,rgba(144,190,203,1) 80%,rgba(144,190,203,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(144,190,203,1) 0%,rgba(144,190,203,1) 20%,rgba(97,141,152,1) 50%,rgba(144,190,203,1) 80%,rgba(144,190,203,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#90becb', endColorstr='#90becb',GradientType=0 ); /* IE6-9 */
    justify-content: center;
    align-items: center;
}

div#product-view div.banner span {
    font-size: 20px;
    color: white;
}

div#product-view div.content {
    padding: 2em;
}

div#product-view div#product-details {
    margin-top: 1.5em;
    text-align: left;
    padding: 15px;
}

div#product-view div#product-details div.carousel div.carousel-inner {
    text-align: center;
}

div#product-view div#product-details div.carousel li.slide {
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: white;
}

div#product-view div#product-details div.carousel li.slide div img {
    pointer-events: all;
}

div#product-view div#product-thumbnails {
    margin-top: 1.5em;
    height: 110px;
    padding: 15px;
}

div#product-view div#product-thumbnails div.product-thumbnail {
    width: 200px;
    height: 100px;
    background-position: center center;
    background-size: cover;
    float: left;
    margin-right: 1.5em;
}

div#product-view div#product-details p.product-description {
    white-space: pre-line;
}

div#product-view div#product-details table.product-description th,
div#product-view div#product-details table.product-description td {
    padding: 2px 1em
}

div#product-view div#product-details table.product-description tr th:nth-child(1),
div#product-view div#product-details table.product-description tr td:nth-child(1) {
    white-space: nowrap;
}

div#product-view div#product-details table.product-description tr th:nth-child(1):after,
div#product-view div#product-details table.product-description tr td:nth-child(1):after {
    content: " : "
}
