div#exhibitions {
    background-color: white;
    color: #436ba5;
    overflow: auto;
    width: 1024px
}

div#exhibitions div.banner {
    display: flex;
    background-color: white;
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url(/media/exhibitions.jpg);
    background-size: cover;
    justify-content: center;
    align-items: center;
    /* (img-height / img-width * container-width) */
    padding-top: 14.5%;
    padding-bottom: 14.5%;
}

div#exhibitions div.banner span {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: white;
}

div#exhibitions div.content {
    padding: 2em;
}

div#exhibitions div.details {
    margin-top: 1.5em;
    text-align: left;
    padding: 15px;
}

div#exhibitions div.details h4:not(:nth-child(1)) {
    margin-top: 1.5em
}

@media only screen and (max-width:480px) {
    div#exhibitions div.banner {
        padding-top: 33.3%;
        padding-bottom: 33.3%;
    }
}
